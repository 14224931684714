.report-table-container {
  width: 100%;
  font-size: 13px;
  overflow-x: scroll;
  table {
    width: 100%;
    th,
    td {
      border: 1px solid black;
      padding: 0px 8px;
      text-overflow: inherit;
    }
    border-collapse: collapse;
  }
  @media print {
    overflow-x: visible;
    font-size: 10px;
    // padding: 60px;
  }
}
