// .schedule-calendar-container {
//   .rbc-current-time-indicator {
//     background-color: #ff4056;
//     height: 2px;
//   }
//   .rbc-time-view {
//     border: 0;
//   }
//   .rbc-row-content {
//     display: none;
//   }
//   .rbc-header + .rbc-header {
//     border-left: 0;
//   }
//   .rbc-allday-cell {
//     display: none;
//   }

//   .rbc-header {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border-bottom: 0;
//     border-left: 10px solid #fff;
//     color: #525252;
//   }

//   .rbc-time-header-content {
//     border-left: 0;
//   }

//   .rbc-today {
//     background-color: #fff;
//   }
// }

// .schedule-event {
//   h6 {
//     color: #018685;
//   }
//   span {
//     color: #9091a7;
//   }
// }
//   .rbc-today {
//     background-color: #fff;
//   }
// }
.Toastify__toast--warning {
  background: #f8aa00 !important;
}
.schedule-event {
  h6 {
    color: #018685;
  }
  span {
    color: #9091a7;
  }
}
.announceBody img {
  max-width: 100%;
}

// .rbc-event-content {
//   font-size: 12px !important;
// }

// .rbc-day-slot .rbc-event-label {
//   display: none;
// }

// .home-schedule-item {
//   height: 100%;
//   color: #8c9cb0;
// }
// .rbc-event,
// .rbc-day-slot .rbc-background-event {
//   padding: 0 !important;
//   border: 0 !important;
// }
